import React from 'react';
import theme from '@/style';
import {SVGProps} from '@/types';

interface IMChatSvgProps extends SVGProps {
  active?: boolean;
}

const IMChat = ({active = false, width, height}: IMChatSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M17 9C20.3273 9 23 11.7 23 15C23 16.8818 22.1545 18.5455 20.8182 19.6364V20.4546C20.8182 20.7273 20.5454 21 20.2727 21H16.7545C13.5636 20.8636 11 18.2182 11 15C11 11.7 13.6727 9 17 9Z"
        fill={active ? theme.basicColor.darkPrimary : '#A7A9B3'}
      />
      <path
        d="M8.00002 2C3.56366 2 0 5.60001 0 10C0 12.5091 1.12729 14.7273 2.90909 16.1818V17.2727C2.90909 17.6364 3.27274 18 3.63637 18H8.32728C12.5818 17.8182 16 14.2909 16 10C16 5.60001 12.4364 2 8.00002 2Z"
        fill={active ? theme.basicColor.primary : '#D5D6DB'}
      />
      <circle cx="3.75" cy="10.25" r="1.25" fill="white" />
      <circle cx="8.25" cy="10.25" r="1.25" fill="white" />
      <circle cx="12.75" cy="10.25" r="1.25" fill="white" />
    </svg>
  );
};

export default IMChat;
