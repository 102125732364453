export const packageId = 5 as number;
export const packageInfo = 'com.sambad.99lotto';
export const defaultChannel = '99lotto';
export const disabledPhoneCode = true;
export const apiSendPhoneCode = false;
export const defaultPhoneCode = '91';
export const homePage = 'Index';
export const defaultApkPath = '99lotto';
export const defaultApkName = '99lotto.apk';
export const defaultApkPrefix = '99lotto-';
export const gameNavigateVersion = 1;
