import {Platform} from 'react-native';
import {createNavigationContainerRef} from '@react-navigation/native';
import {StackActions} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

const isReady = () => navigationRef.isReady();

export function navigate(name, params) {
  isReady() && navigationRef.navigate(name, params);
}

export function replace(name, params) {
  isReady() && navigationRef.dispatch(StackActions.replace(name, params));
}

export function navGoBack() {
  if (navigationRef.canGoBack()) {
    navigationRef.goBack();
  } else {
    navigationRef.navigate('Index');
  }
}
